<template>
  <ys-detail :icon="detailIcon" :loading="loading">
    <Tag size="medium" color="blue" slot="status">{{ midData[0].status|getBaseDataName('启停状态') }}</Tag>
    <template slot="brief">
      <ys-cell title="通知类型" :label="midData[0].smsType|getBaseDataName('通知类型')"/>
      <ys-cell title="渠道" :label="midData[0].channel|getBaseDataName('短信渠道')"/>
      <!-- <ys-cell title="适用范围" :label="midData[0].category|getBaseDataName('适用范围')"/> -->
      <!-- <ys-cell title="通知模板" :label="midData[0].category|getBaseDataName('是否')"/> -->
    </template>
    <template slot="main-left">
      <BaseInfo :tableHeight="pageListHeight" :mid="mid"/>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">其他信息</h1>
      <ys-empty title="暂未开放！"></ys-empty>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import BaseInfo from "@/views/base/notice/sendRecord.vue";

export default {
  name: "detailSendRecord",
  mixins: [detailMixins],
  components: {
    BaseInfo,
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>

<style scoped>
#container {
  width: 100%;
  height: 450px;
}
</style>