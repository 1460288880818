<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" :label-width="100" inline>
      <FormItem label="通知类型" prop="smsType">
        <Select v-model="form.smsType" clearable :disabled="isdisabled" @on-change="roleTypeChange" transfer placeholder="通知类型">
          <Option v-for="item in baseData['通知类型']" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="渠道" prop="channel">
        <Select v-model="form.channel" clearable :disabled="isdisabled" transfer placeholder="渠道">
          <Option v-for="item in baseData['短信渠道']" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- <FormItem label="适用范围" prop="type"> -->
        <!-- <Select v-model="form.type" transfer placeholder="适用范围"> -->
          <!-- <Option :value="0">系统默认角色</Option> -->
          <!-- <Option :value="1">平台角色</Option> -->
          <!-- <Option v-for="item in baseData['适用范围']" :value="item.value" :key="item.value">{{ item.name }}</Option> -->
        <!-- </Select> -->
      <!-- </FormItem> -->
      <FormItem label="状态" prop="status">
        <Select v-model="form.status" clearable placeholder="启停状态">
          <Option v-for="(item,index) in baseData['启停状态']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="相关配置" prop="configContent" style="width: 100%">
        <Input v-model="form.configContent" :disabled="isdisabled" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 5,maxRows: 10}" placeholder="相关配置"></Input>
      </FormItem>
      <FormItem label="备注" prop="desc" style="width: 100%">
        <Input v-model="form.desc" :disabled="isdisabled" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 10}" placeholder="备注"></Input>
      </FormItem>

    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {smsInfo} from "@/api/base";

export default {
  name: 'configureEdit',
  components: {},
  mixins: [edit],
  props: {},
  data() {
    return {
      ruleValidate: {
        smsType: [
          {required: true, type: 'number', message: '请选择所属分类', trigger: 'blur'}
        ],
        channel: [
          {required: true, type: 'number', message: '请选择渠道', trigger: 'blur'}
        ],
        status: [
          {required: true, type: 'number', message: '请选择状态', trigger: 'blur'}
        ],
        configContent: [
          {required: true, message: '请填写相关配置', trigger: 'blur'}
        ],
      },
      form: {
        id: '',
        smsType: 1,
        channel: 1,
        status: 1,
        configContent: '',
        desc: '',
      },
      isdisabled: false,
    }
  },
  computed: {},
  mounted() {
    if (this.mid) {
      smsInfo({id: this.mid}).then(res => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
        this.isdisabled = this.form.status === 1
      })
    }
  },
  methods: {
    roleTypeChange() {},
    save() {
      this.ok('/sms/config/AddOrEdit');
    }
  }
}
</script>

<style lang="less" scoped>

</style>


