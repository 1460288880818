<template>
  <ys-detail :icon="detailIcon" :loading="loading">
    <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('启停状态') }}</Tag>
    <template slot="brief">
      <ys-cell title="通知类型" :label="detail.smsType|getBaseDataName('通知类型')"/>
      <ys-cell title="渠道" :label="detail.channel|getBaseDataName('短信渠道')"/>
      <!-- <ys-cell title="适用范围" :label="detail.category|getBaseDataName('适用范围')"/> -->
      <!-- <ys-cell title="通知模板" :label="detail.category|getBaseDataName('是否')"/> -->
    </template>
    <template slot="main-left">
      <Tabs v-model="tab" name="detail" type="card" :key="detail.id">
        <Tab-pane label="通知模板" name="1" icon="ios-albums">
          <base-info :midData="[detail]" :detail="detail" :tableHeight="pageListHeight" v-if="tab==='1'"/>
        </Tab-pane>
        <Tab-pane label="相关附件" name="2" icon="md-document">
          <ys-empty title="暂未开放！"></ys-empty>
          <!-- <ys-resource :resourceType="8" :tableHeight="pageListHeight" :mid="detailID" v-if="tab==='2'"></ys-resource> -->
        </Tab-pane>
      </Tabs>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">其他信息</h1>
      <ys-empty title="暂未开放！"></ys-empty>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {smsInfo} from "@/api/base";
import BaseInfo from "@/views/base/notice/configure/detail/baseInfo.vue";
// import resource from "_c/resource/resource.vue";

export default {
  name: "detail",
  mixins: [detailMixins],
  components: {
    BaseInfo,
    // resource,
  },
  data() {
    return {}
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      this.detail={};
      smsInfo({id: this.mid}).then(res => {
        this.loading = false;
        this.detail = res.data;
      })
    }
  }
}
</script>

<style scoped>
#container {
  width: 100%;
  height: 450px;
}
</style>