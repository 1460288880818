<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" :label-width="120" inline>
      <FormItem label="系统编号" prop="sysNumber">
        <Input v-model="form.sysNumber" :disabled="isdisabled" placeholder="系统编号"/>
      </FormItem>
      <FormItem label="系统标题" prop="title">
        <Input v-model="form.title" :disabled="isdisabled" placeholder="系统标题"/>
      </FormItem>
      <FormItem label="适用平台" prop="platform">
        <Select v-model="form.platform" :disabled="isdisabled" clearable @on-change="roleTypeChange" transfer placeholder="适用平台">
          <Option v-for="item in baseData['平台类型'].filter(item => item.value !== 6)" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="所属分类" prop="action">
        <Select v-model="form.action" :disabled="isdisabled" clearable transfer placeholder="所属分类" style="width: 100%;">
          <Option v-for="item in baseData['短信所属分类']" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
        <!-- <Button type="primary" style="width: 30%;">新增分类</Button> -->
      </FormItem>
      <FormItem label="接收对象" prop="acceptor">
        <Select v-model="form.acceptor" :disabled="isdisabled" clearable transfer placeholder="接收对象">
          <Option v-for="item in baseData['通知接收对象']" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- <FormItem label="可自定义配置" prop="customConfig">
        <Select v-model="form.customConfig" multiple placeholder="启停状态">
          <Option v-for="(item,index) in baseData['启停状态']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem> -->
      <FormItem label="模板code" prop="templateCode">
        <Input v-model="form.templateCode" :disabled="isdisabled" placeholder="模板code"/>
      </FormItem>
      <FormItem label="状态" prop="status">
        <Select v-model="form.status" clearable transfer placeholder="启停状态">
          <Option v-for="item in baseData['启停状态']" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="可设置启停状态" prop="setStatus">
        <Select v-model="form.setStatus" :disabled="isdisabled" clearable transfer placeholder="可否">
          <Option v-for="item in baseData['启停状态']" :value="item.value" :key="item.value">{{ item.name === '启用' ? '可以' : '不可以' }}</Option>
        </Select>
      </FormItem>
      <FormItem label="可设置接收者" prop="setAcceptor">
        <Select v-model="form.setAcceptor" :disabled="isdisabled" clearable transfer placeholder="可否">
          <Option v-for="item in baseData['启停状态']" :value="item.value" :key="item.value">{{ item.name === '启用' ? '可以' : '不可以' }}</Option>
        </Select>
      </FormItem>
      <FormItem label="模板内容" prop="templateContent" style="width: 100%">
        <Input v-model="form.templateContent" :disabled="isdisabled" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 10}" placeholder="仅预览"></Input>
      </FormItem>
      <FormItem label="备注" prop="desc" style="width: 100%">
        <Input v-model="form.desc" :disabled="isdisabled" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 5}" placeholder="备注"></Input>
      </FormItem>

    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {smsTemplateInfo} from "@/api/base";

export default {
  name: 'detailEdit',
  components: {},
  mixins: [edit],
  props: {},
  data() {
    return {
      ruleValidate: {
        sysNumber: [
          {required: true, message: '请填写系统编号', trigger: 'blur'}
        ],
        action: [
          {required: true, message: '请选择所属分类', trigger: 'blur'}
        ],
      },
      classification: [],
      form: {
        smsConfigId: '',

        id: '',
        sysNumber: '',
        title: '',
        platform: 1,
        acceptor: '',
        customConfig: '',
        templateCode: '',
        status: 1,
        setStatus: 1,
        setAcceptor: 1,
        templateContent: '',
        desc: '',
        action: '',
      },
      isdisabled: false,
    }
  },
  computed: {},
  mounted() {
    if (this.mid) {
      smsTemplateInfo({id: this.mid}).then(res => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
        this.isdisabled = this.form.status === 1
      })
    }
    this.form.smsConfigId = this.midData[0].id
  },
  methods: {
    roleTypeChange() {},
    save() {
      this.ok('/sms/template/AddOrEdit');
    }
  }
}
</script>

<style lang="less" scoped>

</style>


