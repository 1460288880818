<template>
  <div>
    <ys-page-list
        ref="table"
        :multiBtn="multiBtn"
        showContextMenu
        :params="params"
        action="/sms/template/List"
        :tableHeight="resultHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="所属分类" prop="action" show>
          <Select v-model="params.action" clearable transfer placeholder="所属分类" style="width: 150px;">
            <Option v-for="item in baseData['短信所属分类']" :value="item.value" :key="item.value">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :mid="detailID" :midData="midData"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="800" title="新增/修改通知配置">
      <Edit :midData="midData" :mid="detailID" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Edit from "@/views/base/notice/configure/detail/edit.vue";
import Detail from "@/views/base/notice/configure/detail/record.vue";

export default {
  name: 'detailBaseInfo',
  mixins: [pageList],
  components: {
    Edit,
    Detail
  },
  data() {
    return {
      params: {
        action: '',
        keyword: '',
        smsConfigId: ''
      },
      modShow: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 100, title: '编号', key: 'sysNumber', align: 'center',},
        {width: 200, title: '通知标题', key: 'title', align: 'center', renderConfig: {type: 'gotoDetail'}},
        {width: 200, title: '适用平台', key: 'platform', align: 'center', renderConfig: {type: 'baseData', parentName: '平台类型'}},
        {width: 200, title: '所属分类', key: 'action', align: 'center', renderConfig: {type: 'baseData', parentName: '短信所属分类'}},
        {width: 200, title: '模板code', key: 'templateCode', align: 'center',},
        {width: 200, title: '模板内容(仅预览)', key: 'templateContent', align: 'center',},
        // {width: 200, title: '自定义配置', key: 'customConfig', align: 'center',},
        {width: 200, title: '接收人', key: 'acceptor', align: 'center', renderConfig: {type: 'baseData', parentName: '通知接收对象'}},
        {width: 200, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '启停状态'}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看发送记录',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/sms/template/Del', {id: this.tableSelectItem[0].id}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status === 1
            },
          ]
        },
      ]
    },
  },
  mounted() {
    this.params.smsConfigId = this.midData[0].id
    this.getList();
  },
  methods: {}
}
</script>

<style>

</style>