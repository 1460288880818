<template>
  <div>
    <ys-page-list
        ref="table"
        :multiBtn="multiBtn"
        showContextMenu
        keyID="27D1FFAB9C3281D8A6AD616FCE69F5B6"
        :params="params"
        action="/sms/config/List"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="状态" prop="status">
          <Select v-model="params.status" clearable placeholder="启停状态" style="width: 120px">
            <Option v-for="(item,index) in baseData['启停状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <!-- <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem> -->
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="800" title="新增/修改通知配置">
      <Edit :mid="detailID" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Edit from "@/views/base/notice/configure/edit.vue";
import Detail from "@/views/base/notice/configure/detail.vue";

export default {
  name: 'configure',
  mixins: [pageList],
  components: {
    Edit,
    Detail
  },
  data() {
    return {
      params: {
        status: '',
      },
      modShow: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 100, title: '创建人', key: 'createUsername', align: 'center', renderConfig: {type: 'gotoDetail'}},
        {width: 100, title: '通知类型', key: 'smsType', align: 'center', renderConfig: {type: 'baseData', parentName: '通知类型'}},
        {width: 70, title: '渠道', key: 'channel', align: 'center', renderConfig: {type: 'baseData', parentName: '短信渠道'}},
        {minWidth: 300, title: '相关配置', key: 'configContent', align: 'left',},
        {width: 100, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '启停状态'}},
        {minWidth: 200, title: '备注', key: 'desc', align: 'left',},
        {width: 170, title: '创建日期', key: 'createdAt', align: 'center', },
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            { // 启用后不允许删除和修改
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/sms/config/Del', {id: this.tableSelectItem[0].id}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status === 1
            },
          ]
        },
      ]
    },
  },
  mounted() {
    this.getList();
  },
  methods: {}
}
</script>

<style>

</style>